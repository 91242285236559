<template>
  <div>
    <v-row class="mx-0">
      <v-col class="pa-0" :md="showFilterButton ? 11 : 12">
        <tab-section
          :key="component"
          :tab-options="tabDetails"
          @tabChange="tabChange($event)"
        />
      </v-col>
      <v-col v-if="showFilterButton" class="pa-0" md="1">
        <div class="icon-filter text-right pr-4">
            <HbBtn icon @click="toggleActionsFilter" mdi-code="mdi-table-actions-custom-1" />
        </div>
      </v-col>
    </v-row>
    <component :is="component" :level="level" />
  </div>
</template>

<script type="text/babel">

import { EventBus } from '../../EventBus';

export default {
  name: "TabWrapper",
  props: {
    component: {
      type: String,
      default: "",
    },
    tabDetails: {
      type: Array,
      default: () => [],
    }, 
    initialTab: {
      type: String,
      default: "Corporate",
    },
  },

  components: {
    'tab-section': () => import('../includes/Tabs/Index.vue'),
    'settings-products': () => import('./Products.vue'),
    'settings-national-account': () => import('./NationalAccounts/Index.vue'),
    'settings-insurance': () => import('./InsuranceSettings.vue'),
    'settings-fees': () => import('./Fees/Index.vue'),
    'settings-rent': () => import('./Rent/Index.vue'),
    'settings-tax-profiles': () => import('./Tax/Index.vue'),
    'settings-payment-configuration': () => import('./PaymentConfiguration/index.vue'),
    'settings-lease-configuration': () => import('./PropertyTemplates/PropertyCorporateView.vue'),
    'settings-billing': () => import('./BillingSettings.vue'),
    'settings-roles': () => import('./Roles.vue'),
    'settings-merchandise': () => import('./MerchandiseManagement/index.vue'),
    'settings-revenue-management': () => import('./RevenueManagement/Index.vue'),
    // 'settings-notes' :()=> import('./Notes.vue'),
    'settings-communications' :()=> import('./Communication/Index.vue'),
    'settings-lead-management' :()=> import('./LeadManagement/Index.vue'),
    'settings-properties' :()=> import('./Properties/Index.vue'),
  },
  data() {
    return {
      level: "",
    };
  },
  computed: {
    showFilterButton() {
      return this.level === "Property" && this.component === 'settings-merchandise'
    }
  },
  watch: {
    component: {
      immediate: true,
      handler(val) {
        this.level = this.initialTab;
      }
    }
  },
  methods: {
    tabChange(tabName){
        this.level = tabName;
    },
    toggleActionsFilter() {
      EventBus.$emit('HB-Settings-Toggle-Merchandise-Filter')
    }
  },
};
</script>


<style scoped>
.icon-filter {
  border-bottom: 1px solid #dbdcdd;
}
</style>
